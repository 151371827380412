import React, { PureComponent } from 'react';

import i18n from '../../i18n';
import PropTypes from 'prop-types';
import { basePageWrap } from '../BasePage';
import styles from './CategoryPage.module.scss';
import Hero from '../../components/Hero';
import SubNavigation from '../../components/SubNavigation';
import StreamField from '../../components/StreamField';
import GuideList from '../../components/GuideList';
import { ComponentColors } from '../../components/StreamField/StreamField';
import { snakeToPascal } from '../../utils/caseconverters';
import classNames from 'classnames';

const getLastComponentColor = (contentBlocks) => {
    if (!contentBlocks) {
        return '';
    }
    const lastStreamFieldComponent = contentBlocks[contentBlocks.length - 1];
    const componentName = snakeToPascal(lastStreamFieldComponent.type);
    return ComponentColors[componentName];
};

class CategoryPage extends PureComponent {
    state = {};

    static defaultProps = {
        header: null,
    };

    static propTypes = {
        contentBlocks: PropTypes.array,
    };

    render() {
        const { hero, subNavigation, contentBlocks, guideList } = this.props;

        const lastComponentColor = getLastComponentColor(contentBlocks);
        return (
            <div className={styles['CategoryPage']}>
                <Hero {...hero} title={null} />

                <SubNavigation {...subNavigation} />

                {!!contentBlocks && <StreamField items={contentBlocks} />}
                {!!guideList && (
                    <GuideList
                        {...guideList}
                        title={i18n.t('Guides.relatedWithFilter')}
                        extraPaddingTop={lastComponentColor === 'Black'}
                    />
                )}
            </div>
        );
    }
}

export default basePageWrap(CategoryPage);
