import dynamic from 'next/dynamic';
import React, { useEffect, useRef } from 'react';

import PropTypes from 'prop-types';
const VideoModule = dynamic(() => import('../VideoModule'));
import i18n from '../../i18n';
import styles from './Hero.module.scss';
import classNames from 'classnames';

const Hero = ({ title, image, video, preamble, videoEmbed }) => {
    const [isVisible, setVisible] = React.useState(false);

    React.useEffect(() => {
        setVisible(true);
    }, []);

    return (
        <div
            className={classNames(styles['Hero'], {
                [styles['Hero--Video']]: video,
                [styles['Hero--isVisible']]: isVisible,
            })}>
            <div className={styles['Hero__Container']}>
                {!video && image && image.renditions && (
                    <figure className={styles['Hero__BackgroundContainer']}>
                        <picture>
                            <source
                                className={styles['Hero__BackgroundImage']}
                                type="image/webp"
                                sizes="(max-width: 1646px) 1646px,
                                        (max-width: 1200px) 1200px,
                                        (max-width: 600px) 600px,
                                        100vw"
                                srcSet={`${image.renditions.webpXlarge.src} 1646w,
                                     ${image.renditions.webpLarge.src} 1200w,
                                    ${image.renditions.webpMedium.src} 600w`}
                            />
                            <img
                                className={styles['Hero__BackgroundImage']}
                                width={736}
                                height={410}
                                sizes="100vw"
                                srcSet={`${image.renditions.xlarge.src} 1646w,
                                     ${image.renditions.large.src} 1200w,
                                    ${image.renditions.medium.src} 600w`}
                                src={image.renditions.small.src}
                                alt={image.alt}
                                style={{
                                    objectPosition:
                                        image.focal.x + ' ' + image.focal.y,
                                }}
                            />
                        </picture>
                        {image.caption && (
                            <figcaption className={styles['Hero__Caption']}>
                                {i18n.t('generic.photo')} {image.caption}
                            </figcaption>
                        )}
                    </figure>
                )}
                {video && (
                    <div className={styles['Hero__VideoContainer']}>
                        <VideoModule
                            {...video}
                            iframeAttributes={videoEmbed.iframeAttributes}
                            title={title}
                            preamble={preamble}
                        />
                    </div>
                )}

                {title && !video && (
                    <div className={styles['Hero__TextContainer']}>
                        <section className={styles['Hero__InnerContainer']}>
                            <h1 className={styles['Hero__Title']}>{title}</h1>

                            <span className="sr-only">
                                {i18n.t('generic.preamble')} :
                            </span>

                            <p className={styles['Hero__Preamble']}>
                                {preamble}
                            </p>
                        </section>
                    </div>
                )}
            </div>
        </div>
    );
};

Hero.propTypes = {
    title: PropTypes.string,
    preamble: PropTypes.string,
    image: PropTypes.object,
    video: PropTypes.object,
};

Hero.defaultProps = {
    title: '',
    preamble: '',
    image: null,
    video: null,
};

export default Hero;
